<template>
    <div class="dialog-add-account" v-loading="loading">
        <div class="dialog-add-account__title" v-html="$t('documents.title')" />
        <div class="dialog-add-account__desc">
            {{ $t('documents.description') }}
        </div>
        <div class="dialog-add-account-document__form">
            <Selector ref="file" class="application__selector application__input"
                :title="$t('documents.file_type')" 
                :options="files" 
                :select-fitrst-item="false"
                :origin="'documents'"
                @handleChange="(val) => {
                    form.file_type = val
                    form.status=''
                    }" 
            />
            <div v-if="form.file_type === 'invoice'"
                class="form-input"
                :class="[{'form-input--full' : form.invoice_amount},{'form-input--error' : errors.invoice_amount}]"
                >
                <input v-model="form.invoice_amount" type="text" pattern="\d+" required>
                <span class="form-input__label">{{$t('documents.invoice_amount')}}</span>
                <div
                    v-if="errors.invoice_amount"
                    class="form-input__error"
                    v-text="errors.invoice_amount"
                />
            </div>
            <!-- <div
                class="form-input"
                :class="[{'form-input--full' : form.invoice_currency},{'form-input--error' : errors.invoice_currency}]"
                >
                <input v-model="form.invoice_currency" type="text" required>
                <span class="form-input__label">{{$t('documents.invoice_currency')}}</span>
                <div
                    v-if="errors.invoice_currency"
                    class="form-input__error"
                    v-text="errors.invoice_currency"
                />
            </div> -->
            <Selector v-if="form.file_type === 'invoice'" 
                ref="currency" class="application__selector application__input"
                :title="$t('documents.invoice_currency')" 
                :options="currency" 
                :select-fitrst-item="false"
                @handleChange="(val) => form.invoice_currency = val" 
            />
            <Selector v-show="form.file_type === 'agreement' || form.file_type === 'terms' ||  form.file_type === 'act'" 
                ref="status" class="application__selector application__input"
                :title="$t('documents.status')" 
                :options="statuses" 
                :select-fitrst-item="false"
                :fileType="form.file_type"
                @handleChange="(val) => form.status = val" 
            />
            <Selector v-show="form.file_type === 'invoice'" 
                ref="statusInvoice" class="application__selector application__input"
                :title="$t('documents.status')" 
                :options="invoices" 
                :select-fitrst-item="false"
                :fileType="form.file_type"
                @handleChange="(val) => form.status = val" 
            />
            <div class="form-input">
                <!-- <div v-if="filesArr && Object.keys(filesArr).length" class="document__upload-files">
                    <div v-for="(item, idx) in filesArr" :key="idx" class="document__upload-files-item">
                    <span v-if="item.name" v-text="item.name" />
                    <button class="document__upload-files-delete" @click="deleteFile(item.id)">
                        <img src="@/assets/icons/icon-close.svg" alt="">
                    </button>
                    </div>
                </div> -->
                <div v-if="filesArr && Object.keys(filesArr).length" class="document__upload-files">
                    <div  class="document__upload-files-item">
                    <span v-if="filesArr.name" v-text="filesArr.name" />
                    <button class="document__upload-files-delete" @click="deleteFile(filesArr.id)">
                        <img src="@/assets/icons/icon-close.svg" alt="Close icon">
                    </button>
                    </div>
                </div>
                <label class="document__upload">
                    <div class="button button--empty">{{$t('documents.attach_file')}}</div>
                    <input :disabled="Object.keys(filesArr).length > 0" id="files" type="file" @change="checkFile">
                </label>
            </div>
      
            <ButtonElement
                :text="$t('button_props.add_document')"
                :disabled="isButtonDisabled"
                @click-button="addDocument"
                class="application__send-button"
            />
        </div>
    </div>
</template>
    
<script>
import formValidate from '../../mixin/formValidate'
import getCurrentDate from '../../mixin/getCurrentDate'
import ButtonElement from '../../components/elements/ButtonElement'
//   import DateRangePicker from 'vue2-daterange-picker'
import Selector from "@/components/sort/Selector";
// import axios from 'axios'


// const apiUrl = process.env.VUE_APP_API_URL

export default {
    name: 'AddDocument',
    components: {
        //   DateRangePicker,
        ButtonElement,
        Selector,
    },
    mixins: [formValidate, getCurrentDate],
    data() {
        return {
            lang: this.$i18n._vm.locale,
            loading: false,
            isShow: false,
            respErr: '',
            dataError: false,
            currentDate: '',
            hours: new Date().getHours(),
            minutes: new Date().getMinutes(),
            seconds: new Date().getSeconds(),
            isDisabled: false,
            date: {
                startDate: new Date(),
                endDate: new Date()
            },
            minDate: null,
            locales: {
                ru: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    daysOfWeek: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                    monthNames: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'],
                    firstDay: 1
                },
                en: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    daysOfWeek: ['sun', 'mon', 'tu', 'wen', 'th', 'fr', 'sat'],
                    monthNames: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
                    firstDay: 1
                }
            },
            months: {
                ru: [
                    {
                        id: 0,
                        shortName: 'янв'
                    },
                    {
                        id: 1,
                        shortName: 'фев'
                    },
                    {
                        id: 2,
                        shortName: 'мар'
                    },
                    {
                        id: 3,
                        shortName: 'апр'
                    },
                    {
                        id: 4,
                        shortName: 'май'
                    },
                    {
                        id: 5,
                        shortName: 'июн'
                    },
                    {
                        id: 6,
                        shortName: 'июл'
                    },
                    {
                        id: 7,
                        shortName: 'авг'
                    },
                    {
                        id: 8,
                        shortName: 'сен'
                    },
                    {
                        id: 9,
                        shortName: 'окт'
                    },
                    {
                        id: 10,
                        shortName: 'нояб'
                    },
                    {
                        id: 11,
                        shortName: 'дек'
                    }
                ],
                en: [
                    {
                        id: 0,
                        shortName: 'jan'
                    },
                    {
                        id: 1,
                        shortName: 'feb'
                    },
                    {
                        id: 2,
                        shortName: 'mar'
                    },
                    {
                        id: 3,
                        shortName: 'apr'
                    },
                    {
                        id: 4,
                        shortName: 'may'
                    },
                    {
                        id: 5,
                        shortName: 'jun'
                    },
                    {
                        id: 6,
                        shortName: 'jul'
                    },
                    {
                        id: 7,
                        shortName: 'aug'
                    },
                    {
                        id: 8,
                        shortName: 'sep'
                    },
                    {
                        id: 9,
                        shortName: 'oct'
                    },
                    {
                        id: 10,
                        shortName: 'nov'
                    },
                    {
                        id: 11,
                        shortName: 'dec'
                    }
                ],
            },
            activePeriod: 'day',
            form:{
                file_type: null,
                invoice_amount:'',
                invoice_currency:'',
                status:'',
            },
            errors: {
                invoice_amount:'',
                invoice_currency:'',
                status:'',
            },
            filesArr: {},
            documentAdded: false
        }
    },
    computed: {
        activeAccount() {
            return this.$store.state.Auth.activeAccount
        },
        start_at() {
            return this.fixDateFormatNumbers(this.date.startDate)
        },
        end_at() {
            return this.fixDateFormatNumbers(this.date.endDate)
        },
        files() {
            return this.$store.state.Documents.files
        },
        statuses() {
            return this.$store.state.Documents.statuses
        },
        currency() {
            return this.$store.state.Documents.currency
        },
        invoices() {
            return this.$store.state.Documents.invoices
        },
        fileType() {
            return this.form.file_type
        },
        isButtonDisabled() {
            //:disabled="!form.file_type || !form.invoice_amount || !form.invoice_currency || !form.status || !filesArr.length"
            if(this.form.file_type === 'invoice' && (this.form.invoice_amount && this.form.invoice_currency && this.form.file_type && this.form.status && Object.keys(this.filesArr).length)){
                return false
            } else if(this.form.file_type !== 'invoice' && ( this.form.file_type && this.form.status && Object.keys(this.filesArr).length )) {
                return false
            }
            return true
        }
    },

    created() {
        this.currentDate = this.fixDateFormatNumbers(new Date())
    },
    watch:{
        fileType(val){
            if(val && val === 'Monthly report'){
                this.form.status = 'done'
            }
        }
    },
    methods: {
        closeModal() {
            this.$store.dispatch('Dialog/closeDialog')
        },
        cleanData() {
            if (this.$refs.file) {
                this.$refs.file.selectedValue = null
            }
            if (this.$refs.status) {
                this.$refs.status.selectedValue = null
            }
            if (this.$refs.statusInvoice) {
                this.$refs.statusInvoice.selectedValue = null
            }
            if (this.$refs.currency) {
                this.$refs.currency.selectedValue = null
            }
            for (let key in this.form) {
                this.form[key] = null
            }
            this.filesArr = {}
            this.documentAdded = false
        },
        fixDate(start, end) {
            if (start && end) {
                let str = ''
                const startDay = start.getDate()
                const endDay = end.getDate()

                const startMonth = start.getMonth()
                const endMonth = end.getMonth()
                const nameStartMonth = this.locale_lang === "RU" ? this.months.find(el => el.id === startMonth).shortName : this.monthsEN.find(el => el.id === startMonth).shortName
                const nameEndMonth = this.locale_lang === "RU" ? this.months.find(el => el.id === endMonth).shortName : this.monthsEN.find(el => el.id === endMonth).shortName

                if (startDay === endDay && startMonth === endMonth) {
                    str = startDay + ' ' + nameStartMonth
                } else if (startMonth === endMonth) {
                    str = startDay + '-' + endDay + ' ' + nameStartMonth
                } else {
                    str = startDay + ' ' + nameStartMonth + ' - ' + endDay + ' ' + nameEndMonth
                }

                return str
            }
        },
        fixDateFormatNumbers(fixedDate) {
            if (fixedDate) {
                let date = new Date(fixedDate)
                let str = ''
                const startDay = String(date.getDate()).padStart(2, '0')
                const startMonth = String(date.getMonth() + 1).padStart(2, '0');
                const startYear = date.getFullYear()
                str = startYear + '-' + startMonth + '-' + startDay
                return str
            }
        },
        checkFile(e) {
            const files = e.target.files;
            if (files && files.length) {
                if (!this.documentAdded) {
                    let reader = new FileReader();
                    for (let i = 0; i < files.length; i++) {
                        const el = files[i];
                        const fileType = el.type;
                        if (
                            (fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
                            !this.documentAdded
                        ) {
                            reader.onload = (e) => {
                                this.filesArr = { id: i + el.name, name: el.name, src: e.target.result, type: 'document', file: el };
                            };
                            reader.readAsDataURL(el);
                            this.documentAdded = true;
                        }
                    }
                }
            }
        },
        deleteFile() {
            //add id as argument
            // this.filesArr = this.filesArr.filter(el => el.id !== id)
            this.filesArr = {}
            this.documentAdded = false
        },
        addDocument() {
                if(this.form.file_type === 'invoice'){
                    this.validateInvoice()
                    if(!this.validateInvoice()) {
                        return
                    }
                }
                const data = new FormData()
                for (let key in this.form) {               
                if (this.form.file_type === 'invoice' || key !== 'invoice_amount' && key !== 'invoice_currency') {
                     data.append(key, this.form[key])
                }
                }
                data.append('business_account_id', this.activeAccount.id)
                data.append('stored_at', this.getCurrentDate())
                // data.append('sort_by', 'file_number')

                if (this.filesArr && Object.keys(this.filesArr).length) {
                    data.append('file', this.filesArr.file)
                    data.append('file_format', this.getFileExtension())
                }
                console.log([...data.entries()])
                this.loading = true
                this.$store.dispatch('Documents/addDocument', data)
                .then(() => {
                    this.cleanData()
                    this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
                    this.$notify({
                    message: this.$t('documents.success'),
                    type: 'success'
                    });
                })
                .catch((error) => {
                    console.log(error)
                    this.$notify({
                    message: this.$t('documents.error_message'),
                    type: 'error'
                    });
                })
                .finally(() => {
                    this.loading = false
                })
             
        },
        getMIMEType() {
            if (this.filesArr && this.filesArr.file) {
                return this.filesArr.file.type;
            } else {
                return null;
            }
        },
        getFileExtension() {
            if (this.filesArr && this.filesArr.name) {
                return this.filesArr.name.split('.').pop();
            } else {
                return null;
            }
        },
        validateInvoiceNumber() {
            if (this.form.invoice_amount) {
                const regex = /^\d+$/;
                return regex.test(this.form.invoice_amount)
            }
        },
        validateInvoice() {
            if (this.form.invoice_amount) {
               if (!this.validateInvoiceNumber()) {
                this.errors.invoice_amount = this.$t('documents.invoice_amount_error')
                return false
               }
               return true
            }
        }
    }
}
</script>
    
<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

//   @keyframes settingsRotate {
//     from {
//       transform: rotate(0);
//     }

//     to {
//       transform: rotate(360deg);
//     }
//   }

//   .fade-enter-active,
//   .fade-leave-active {
//     transition: opacity .3s;
//   }

//   .fade-enter,
//   .fade-leave-to {
//     opacity: 0;
//   }

//   .error {
//     color: red;
//   }

//   .save-users-data {
//     border: 1px solid #d8d9da;
//     font-weight: 700;
//     font-size: 1.6rem;
//     line-height: 1.9rem;
//     border-radius: 4px;
//     height: 3.5rem;
//     min-width: 12.5rem;
//     text-align: center;
//     padding-top: 7px;
//     margin-right: 1rem;
//   }


.dialog-add-account-document {
    
    &__data-picker {
        display: flex;
        justify-content: space-between;
    }

    &__data-title {
        font-weight: 700;
    }

    &__form {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 2rem;
        .selector__content {
            width: 100%;
        }
        .selector__title {
            font-size: 1.6rem;
            color: #878B93;
        }
        .form-input {
            margin: 0;
        }
        .document__upload {
            input {
            display: none;
            }

            &-files {
            width: 100%;
            margin-bottom: 10px;

                &-item {
                    padding: 1.4rem 0;
                    border-bottom: 1px solid $gray7;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:last-child {
                    border: none;
                    }
                }

                &-delete {
                    background: none;
                    border: none;
                }
            }

            .button {
                font-weight: 400;
                color: var(--primary);
                background: #fff;
                font-size: 1.6rem;
                line-height: 1.9rem;
                border-radius: 4px;
                border: 1.5px solid var(--primary);
                min-width: 12.5rem;
                transition: 0.3s ease-out;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5rem;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background: var(--primary);
                }
            }
        }
        .button {
            height: 5rem;
        }

    }
}
</style>
    